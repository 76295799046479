<template>
  <div class="container-fluid">
    <BrandNavTab />
    <div class="p-lg-2">
      <div class="row flex-lg-row flex-column-reverse">
        <div class="col-md-7">
          <h2 class="subtitle roboto bold">Edit Brand</h2>
          Personalize your brand to engage more customers.
          <hr />
          <div class="row mb-2">
            <div class="col-md-4 pt-2">Brand Name <b style="color: red;">*</b></div>
            <div class="col-md-7">
              <InputText
                v-model="$v.formData.name.$model"
                placeholder="Enter Brand Name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 pt-2">Short Description</div>
            <div class="col-md-7">
              <InputText
                v-model="$v.formData.description.$model"
                placeholder="Enter Brand Short Description"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 pt-2">Description</div>
            <div class="col-md-7">
              <InputText
                v-model="$v.formData.description_banner.$model"
                placeholder="Enter Banner Description"
              />
            </div>
          </div>

          <hr />
          <h2 class="subtitle roboto bold">Images</h2>

          <MyInputFormImageCropper
            id="image-banner-square"
            v-model="$v.image_banner_square.$model"
            label="Brand Logo"
            type="image"
            :width="480"
            :height="480"
            :state="(!$v.image_banner_square.$error || !$v.image_banner_square.$invalid) ? null : false"
            :classComponent="{ 'is-invalid': $v.image_banner_square.$error }"
            :invalidMessage="[
              !$v.image_banner_square.required ? 'Brand logo is required.' : null,
            ]"
            :src="imageBannerSquareSrc"
            :required="!$v.image_banner_square.required ? true : null"
            :labelCols="4"
            :contentCols="7"
          />
          <!-- <MyInputFormImageCropper
            id="image-banner-desktop"
            v-model="$v.image_banner.$model"
            label="Banner Desktop"
            type="image"
            :width="1920"
            :height="568"
            :state="(!$v.image_banner.$error || !$v.image_banner.$invalid) ? null : false"
            :classComponent="{ 'is-invalid': $v.image_banner.$error }"
            :invalidMessage="[
              !$v.image_banner.required ? 'Brand banner desktop is required.' : null,
            ]"
            :src="imageBannerSrc"
            :required="!$v.image_banner.required ? true : null"
            :labelCols="4"
            :contentCols="7"
          /> -->
          <MyInputFormImageCropper
            id="image-banner-desktop"
            v-model="$v.image_banner.$model"
            label="Banner Desktop"
            type="image"
            :width="1920"
            :height="568"
            :src="imageBannerSrc"
            :labelCols="4"
            :contentCols="7"
          />
          <MyInputFormImageCropper
            id="image-banner-mobile"
            v-model="$v.image_banner_mobile.$model"
            label="Banner Mobile"
            type="image"
            :width="1280"
            :height="480"
            :state="(!$v.image_banner_mobile.$error || !$v.image_banner_mobile.$invalid) ? null : false"
            :classComponent="{ 'is-invalid': $v.image_banner_mobile.$error }"
            :invalidMessage="[
              !$v.image_banner_mobile.required ? 'Brand banner mobile is required.' : null,
            ]"
            :src="imageBannerMobileSrc"
            :required="!$v.image_banner_mobile.required ? true : null"
            :labelCols="4"
            :contentCols="7"
          />
          <div v-for="(product, i) in formData.highlights" :key="i">
            <hr />
            <h2 class="subtitle roboto bold">Highlight #{{i + 1}}</h2>
            <div class="row mb-2">
              <div class="col-md-4 pt-2">Product</div>
              <div class="col col-lg-7">
                <InputProduct
                  v-model="$v.formData.highlights.$model[i].product_id"
                  :options="activeProducts ? activeProducts.data : []"
                  :disabled="activeProducts && activeProducts.data.length == 0"
                />
                <!-- <InputProduct
                  v-model="$v.formData.highlights.$model[i].product_id"
                  :options="activeProducts ? activeProducts.data : []"
                  :disabled="activeProducts && activeProducts.data.length == 0"
                  @scrollend="50 * (activeProducts.meta.offset + 1) <= activeProducts.meta.total ? getActiveProducts(++activeProducts.meta.offset) : ''"
                /> -->
              </div>
              <div class="col-1" :class="!isLoading && activeProducts && activeProducts.data.length > 0 ? 'd-none' : 'd-flex align-items-center'">
                <font-awesome icon="spinner" spin />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-md-4 pt-2">Link URL</div>
              <div class="col-md-7">
                <InputText
                  v-model="$v.formData.highlights.$model[i].link_url"
                  placeholder="Enter Custom Link URL"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-4 pt-1">Status</div>
              <div class="col-md-7 d-flex">
                <b-form-checkbox switch size="lg" v-model="$v.formData.highlights.$model[i].status"></b-form-checkbox>
                <div class="d-inline pt-1">
                  {{$v.formData.highlights.$model[i].status ? "Active" : "Inactive"}}
                </div>
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col-md-4 pb-2">Image</div>
              <div class="col-md-7">
                <vue-cropper
                  :class="{'d-none' : (formData.highlights[i].image_url && formData.highlights[i].image_url.indexOf('https://') == 0) || !formData.highlights[i].image_url}"
                  ref="cropper"
                  :viewMode="2"
                  :aspect-ratio="1 / 1"
                  :responsive="true"
                  :zoomable="false"
                  :autoCropArea="1"
                  @cropend="cropend(i)"
                />
                <b-form-file class="mt-2" accept="image/jpeg" @input="setImage($event, i)" />
              </div>
            </div>
          </div>
          <!-- <div class="shadow mb-5">&nbsp;</div> -->
          <hr>
        </div>
        <div class="col-md-4">
          <h2 class="subtitle roboto bold">Live Preview</h2>
          <BrandShowcase :brand="Object.assign({}, brand, formData)" />
          <div class="shadow bg-transparent mb-5">&nbsp;</div>
        </div>
      </div>
      
      <h2 class="subtitle roboto bold">Content</h2>
      <!-- Desktop Content -->
      <div class="content-brand row mb-2">
        <div class="content-label pt-2">
          Desktop Content
        </div>
        <div class="col-md-7">
          <vue-ckedit v-model="formData.content" />
        </div>
      </div>
      <!-- Mobile Content -->
      <div class="content-brand row mb-2">
        <div class="content-label pt-2">
          Mobile Content
        </div>
        <div class="col-md-7">
          <vue-ckedit v-model="formData.content_mobile" />
        </div>
      </div>
      <div class="shadow mb-5">&nbsp;</div>

      <div class="d-flex">
        <button type="button" class="ml-auto btn btn-info" @click="onsubmitV2">Save</button>
        <router-link class="ml-2 btn btn-secondary" to="/manage-brand">Cancel</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import InputText from "@/components/formCustom/InputText";
// import InputSelect from "@/components/formCustom/InputSelect";
import InputProduct from "@/components/formCustom/InputProduct";
import BrandNavTab from "@/components/brand/BrandNavTab";
import BrandShowcase from "@/components/brand/BrandShowcase";
const MyInputFormImageCropper = () => import("@/components/form/MyInputFormImageCropper");

import { required, requiredIf, numeric } from "vuelidate/lib/validators";

const isBoolean = (value) => /bool/i.test(typeof Boolean(value))

const highlightTemplate = (highlight) => ({
  product_id: highlight ? highlight.product.id : null,
  link_url: highlight ? highlight.link_url : null,
  status: highlight ? highlight.status : false,
  image_url: highlight ? highlight.image_url : null,
})

const compareData = (dataA, dataB) => {
  // return true if same, false if different
  try {
    const keys = Object.keys(dataA)
    for (let i = 0; i < keys.length; i++)
      if (dataA[keys[i]] != dataB[keys[i]]) return true
  } catch {
    return true
  }
  return false
}

const dataURItoBlob = (dataURI) => {
  if (dataURI && dataURI.indexOf("https://") == 0 || !dataURI) return
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

const initialData = () => ({
  formData: {
    name: null,
    description: null,
    description_banner: null,
    highlights: null,
    content: "",
    content_mobile: "",
  },
  image_banner_square: null,
  image_banner: null,
  image_banner_mobile: null,

  imageBannerSquareSrc: "",
  imageBannerSrc: "",
  imageBannerMobileSrc: "",

  image_highlight_1: null,
  image_highlight_2: null,
  image_highlight_3: null,
})

export default {
  name: "EditBrand",
  components: {
    InputText,
    // InputSelect,
    InputProduct,
    BrandNavTab,
    BrandShowcase,
    MyInputFormImageCropper
  },
  computed: {
    brandId() {
      return this.$route.params.id
    },
  },
  data() {
    return Object.assign({
      activeProducts: null,
      brand: null,
      isLoading: true,
    }, initialData())
  },
  validations: {
    formData: {
      name: { required },
      description: {},
      description_banner: {},
      highlights: {
        $each: {
          product_id: { numeric, required: requiredIf((self) => !self.image_url && self.status) },
          link_url: {},
          status: { required, isBoolean },
          image_url: { required: requiredIf((self) => !self.product_id && self.status) }
        }
      }
    },
    image_banner_square: {
      required: requiredIf(function() {
        return !this.imageBannerSquareSrc
      }),
    },
    image_banner: {
      // required: requiredIf(function() {
      //   return !this.imageBannerSrc
      // }),
    },
    image_banner_mobile: {
      required: requiredIf(function() {
        return !this.imageBannerMobileSrc
      }),
    },
  },
  mounted() {
    this.getActiveProducts()
    this.getBrand(this.brandId)
    // const brand = this.$store.getters.getEditBrand
    // if (!brand) this.getBrand(this.brandId)
  },
  methods: {
    cropend(index) {
      const dataURL = this.$refs.cropper[index].getCroppedCanvas().toDataURL('image/jpeg', 0.9)
      const data = this.formData.highlights[index]
      data.image_url = dataURL
      this.formData.highlights.splice(index, 1, data)
    },
    setImage(file, index) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
      } else if (Math.round(file.size / 1000) > 1000) {
        toaster.make("File size must not exceed 1 MB", "danger")
      } else if (typeof FileReader === 'function') {
        const img = new Image()
        let imgWidth, imgHeight
        img.src = window.URL.createObjectURL(file)
        img.onload = (e) => {
					if (e['path']) { // Chromium
						imgWidth = e['path'][0].naturalWidth;
						imgHeight = e['path'][0].naturalHeight;
					} else if (e['originalTarget']) { // Firefox
						imgWidth = e['originalTarget'].naturalWidth;
						imgHeight = e['originalTarget'].naturalHeight;
          } else {
            imgWidth = img.width;
            imgHeight = img.height;
          }
          if (imgWidth == 2000 && imgHeight == 2000) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const data = this.formData.highlights[index]
              data.image_url = event.target.result
              this.formData.highlights.splice(index, 1, data)
              // rebuild cropperjs with the updated source
              this.$refs.cropper[index].replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            toaster.make("Image resolution must be exactly 2000x2000 pixels", "danger")
          }
        }
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    async onsubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) toaster.make("Incomplete form data, please fill-in all required fields", "danger")
      else {
        let change = false
        const el = this.$v.formData.highlights.$model
        for (let i = 0; i < el.length; i++) {
          const prevData = this.brand.highlights[i] ? {
            id: this.brand.highlights[i].id,
            product_id: this.brand.highlights[i].product?.id,
            link_url: this.brand.highlights[i].link_url,
            status: this.brand.highlights[i].status,
          } : null
          const data = {
            id: el[i].id,
            product_id: el[i].product_id || null,
            link_url: el[i].link_url,
            status: el[i].status,
            brand_description: this.formData.description,
            content: this.formData.content,
            content_mobile: this.formData.content_mobile,
          }
          const image = dataURItoBlob(this.formData.highlights[i].image_url)
          const isDifferent = image ? true : compareData(prevData, data)
          if (isDifferent && (data.id || data.status) || data.brand_description != this.brand.description || data.content != this.brand.content || data.content_mobile != this.brand.content_mobile) { // is different & required data exists
            try {
              const form = new FormData()
              form.append("data", JSON.stringify(data))
              form.append("image", image || null)
              const res = await (data.id ? this.$api.brand.editHighlight(this.brandId, form) : this.$api.brand.addHighlight(this.brandId, form))
              if (res.status === 200) {
                change = true
                if (!isDifferent && data.brand_description != this.brand_description) break;
              }
              else toaster.make(res.data.message, "danger")
            } catch (e) {
              console.error(e);
            }
          }
        }
        if (!change) toaster.make("Nothing changed")
        else {
          toaster.make("Brand highlight(s) has been changed", "success")
          this.$router.push("/manage-brand")
        }
        // const data = {
        //   brand_description: this.formData.description,
        //   highlight_product: [...this.$v.formData.highlights.$model.map(el => {
        //     return el.status || (el.image_url && el.image_url.indexOf("https://") == 0) ? {
        //       product_id: el.product_id || null,
        //       link_url: el.link_url,
        //       status: el.status,
        //     } : null
        //   })].filter(el => el)
        // }
        // const [image1, image2, image3] = [dataURItoBlob(this.formData.highlights[0].image_url), dataURItoBlob(this.formData.highlights[1].image_url), dataURItoBlob(this.formData.highlights[2].image_url)]
        // const form = new FormData()
        // form.append("data", JSON.stringify(data))
        // if (image1) form.append("image_1", image1)
        // if (image2) form.append("image_2", image2)
        // if (image3) form.append("image_3", image3)
        // try {
        //   const res = await this.$api.brand.addHighlight(this.brandId, form)
        //   if (res.status === 200) toaster.make(res.data.message, "success")
        //   else toaster.make(res.data.message, "danger")
        // } catch (e) {
        //   console.error(e);
        // }
      }
    },
    async onsubmitV2() {
      try {
        this.$v.$touch()
        if (this.$v.$invalid) return this.$helpers.toaster.make("Incomplete form data, please fill-in all required fields", "danger")

        let highlight_products = []; //* Variable to set submitted highlight product data

        const highlights = this.$v.formData.highlights.$model;
        for (let i = 0; i < highlights.length; i++) { 
          const el = highlights[i];
          highlight_products.push({
            id: el.id,
            product_id: el.product_id || null,
            link_url: el.link_url,
            status: el.status,
          });
          const image = dataURItoBlob(highlights[i].image_url);
          if (image) this.$data[`image_highlight_${i+1}`] = image;
        }
        const form = new FormData();
        const submittedData = {
          name: this.formData.name,
          description: this.formData.description,
          description_banner: this.formData.description_banner,
          content: this.formData.content,
          content_mobile: this.formData.content_mobile,
          highlight_products
        };
        form.append("data", JSON.stringify(submittedData));
        if (this.image_banner) form.append("image_banner", this.image_banner);
        if (this.image_banner_mobile) form.append("image_banner_mobile", this.image_banner_mobile);
        if (this.image_banner_square) form.append("image_banner_square", this.image_banner_square);
        if (this.image_highlight_1) form.append("image_highlight_1", this.image_highlight_1);
        if (this.image_highlight_2) form.append("image_highlight_2", this.image_highlight_2);
        if (this.image_highlight_3) form.append("image_highlight_3", this.image_highlight_3);
        const res = await this.$api.brand.editBrand(this.brandId, form);
        if (res.status === 200) {
          this.$helpers.toaster.make("Brand data has been changed", "success");
          this.$router.push("/manage-brand");
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getActiveProducts(page = 0) {
      this.isLoading = true
      try {
        const res = await this.$api.product.getProductsByBrand(50, page, null, [this.brandId], null)
        if (res.status === 200) {
          this.activeProducts = {
            meta: res.data.meta,
            data: [...this.activeProducts?.data ?? [], ...res.data.data.result],
          }
          this.isLoading = false
          if (this.activeProducts.meta.limit * (this.activeProducts.meta.offset + 1) <= this.activeProducts.meta.total) this.getActiveProducts(++this.activeProducts.meta.offset)
        }
        else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async getBrand(brandId) {
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) {
          const d = res.data.data.find(el => el.brand.id == brandId)
          this.brand = Object.assign({}, d.brand, {highlights: d.highlight_product_list})
          this.formData.name = this.brand.name
          this.formData.description = this.brand.description
          this.formData.description_banner = this.brand.description_banner
          this.formData.content = this.brand.content
          this.formData.content_mobile = this.brand.content_mobile
          this.formData.highlights = [...this.brand.highlights.map(el => ({
            id: el.id,
            product_id: el.product?.id,
            link_url: el.link_url,
            status: el.status,
            image_url: el.image_url,
            product: {
              slug: el.product?.slug
            }
          })), ...Array(3 - this.brand.highlights.length).fill(null)]
          for (const index in this.formData.highlights)
            this.formData.highlights[index] || this.formData.highlights.splice(index, 1, highlightTemplate())
          // this.formData.image_url = brand.image_url
          // const highlights = brand.highlights.map(el => highlightTemplate(el))
          // this.formData.highlights = [...highlights, ...Array(3 - highlights.length).fill(highlightTemplate())]
          // set preview image
          this.imageBannerSrc = d.brand.image_banner;
          this.imageBannerMobileSrc = d.brand.image_banner_mobile;
          this.imageBannerSquareSrc = d.brand.image_banner_square;
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
  },
}
</script>
<style scoped>
.content-label {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 19.333%;
  max-width: 19.333%;
}
</style>
