<template>
  <div class="position-relative">
    <input
      type="text"
      v-model="localText"
      :placeholder="placeholder || 'Select Product'"
      class="form-control"
      :disabled="disabled"
      @input="$emit('input')"
    />
    <div class="product-options" @scroll="scrollEnd">
      <div v-for="(option, i) in localOptions.filter(el => localText ? new RegExp(localText, 'i').test(el.name) : el)" :key="i" @click="input(option)">
        {{option.name}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputProduct",
  props: ["options", "value", "placeholder", "disabled"],
  data() {
    return {
      localText: null,
      localOptions: this.options || [],
      model: this.value,
    }
  },
  methods: {
    scrollEnd(event) {
      const element = event.target
      if (element.scrollTop == element.scrollHeight - element.clientHeight)
        this.$emit("scrollend")
    },
    input(option) {
      this.localText = option.name
      this.$emit("input", parseInt(option.id))
      this.$emit("inputDetail", option)
    },
  },
  watch: {
    options(newVal) {
      this.localOptions = [...newVal].sort((a, b) => a.name.localeCompare(b.name))
      this.localText = this.localOptions.find(el => el.id == this.model)?.name
    },
    value(newVal) {
      this.model = newVal
    }
  }
}
</script>
<style scoped>
.product-options {
  position: absolute;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  background: white;
  overflow-y: auto;
  visibility: collapse;
  width: 100%;
  max-height: 256px;
  opacity: 0;
  transition: 0s all;
  z-index: 9;
}
input[type="text"]:focus + .product-options {
  opacity: 1;
  visibility: visible;
}
.product-options > div {
  cursor: pointer;
  padding: .5rem;
}
.product-options > div:hover {
  background: #ced4da;
}
</style>
